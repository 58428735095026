<template>
  <div class="app-container">
    <div class="password">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form :model="form" :rules="rules" ref='form' label-width="80px">
            <!-- <el-form-item label="账号">
            <el-input v-model="account" :disabled='true'></el-input>
          </el-form-item> -->
            <el-form-item label="原密码" prop='oldPwd'>
              <el-input v-model="form.oldPwd" show-password autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop='newPwd'>
              <el-input v-model="form.newPwd" show-password autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop='rePwd'>
              <el-input v-model="form.rePwd" show-password autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="changeHandle('form')" size='medium'>修改</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="16"></el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
export default {
  name: 'password',
  data() {
    var checkOldPwd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('原始密码非空'));
      }
      callback();
    };
    var chackNewPwd = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码至少6位'));
      } else {
        if (this.form.rePwd !== '') {
          this.$refs.form.validateField('rePwd');
        }
        callback();
      }
    };
    var chackRePwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.newPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      expertId: '',
      account: '',
      form: {
        account: '',
        oldPwd: '',
        newPwd: '',
        rePwd: '',
      },
      rules: {
        oldPwd: [{
          validator: checkOldPwd,
          trigger: 'blur'
        }],
        newPwd: [{
          validator: chackNewPwd,
          trigger: 'blur'
        }],
        rePwd: [{
          validator: chackRePwd,
          trigger: 'blur'
        }]
      }
    }
  },
  created() {
    // this.expertId = sessionStorage.getItem('expertId');
    this.account = this.$store.state.professor.account;
  },
  methods: {
    changeHandle(form) {
      console.log(form);
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.changePassword();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    changePassword() {
      let param = {
        // id: this.expertId,
        // ip: 3,
        oldPassword: this.form.oldPwd,
        newPassword: this.form.newPwd
      };
      this.$api.user.postEditPwd(param)
        .then(res => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message.success("修改成功！");
          }
          else {
            this.$message.warning('原密码错误！');
          }
        }).catch(err => {
          console.log('服务器维护!');
        })
      // this.$axios.post("/user/modify_password", param)
      //   .then(res => {
      //     console.log(res);
      //     if(res.data.code==1){
      //       this.$message.success("修改成功！");
      //     }
      //     else{
      //       this.$message.warning('原密码错误！');
      //     }
      //   }).catch(err => {
      //     console.log('服务器维护!');
      //   })
    }
  },
  components: {

  }
}
</script>

<style scoped>
.password {
  background-color: #fff;
  padding: 20px;
  height: calc(100vh - 130px);
}
</style>